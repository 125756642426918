import {useEffect, useState, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fromMetrics} from 'store/selectors';
import {metricsRawDataRequest} from 'store/actions';
import {useCampaign} from 'hooks';

const useConversionMetrics = () => {
  const dispatch = useDispatch();
  const {campaignSlug} = useParams();
  const campaign = useCampaign();
  const [filteredConversions, setFilteredConversions] = useState([]);

  useEffect(() => {
    campaign?.has_conversions &&
      dispatch(metricsRawDataRequest('conversions', {campaign: campaignSlug}));
  }, [campaign?.has_conversions, campaignSlug, dispatch]);

  const rawConversions = useSelector((state) => fromMetrics.getRaw(state, 'conversions'));
  console.log(filteredConversions);

  useEffect(() => {
    setFilteredConversions(rawConversions);
  }, [rawConversions]);

  const applyConversionFilters = useCallback(
    (filters) => {
      const {startDate, endDate, uniqueClicksOnly, selectedComponentTypes, selectedBroadcasters} =
        filters;
      let filteredConversions = rawConversions;

      if (uniqueClicksOnly) {
        const uniqueConversions = {};
        filteredConversions.reverse().forEach((c) => {
          uniqueConversions[`${c.ip_addr}-${c.component_id}-${c.broadcaster}`] = c;
        });
        filteredConversions = Object.values(uniqueConversions);
      }

      const visibleBroadcasters = selectedBroadcasters.map((b) => b.username);
      const visibleComponentTypes = selectedComponentTypes.map((c) => c.slug);

      // Only filter conversions by filters if it has been set (i.e. `visibleLabels` exists)
      const conversionComparator = (dataPoint, filterSet, dataField) => {
        return filterSet ? filterSet.includes(dataPoint[dataField]) : true;
      };

      setFilteredConversions(
        filteredConversions.filter(
          (conversion) =>
            conversion.time >= startDate &&
            conversion.time <= endDate &&
            conversionComparator(conversion, visibleBroadcasters, 'broadcaster') &&
            conversionComparator(conversion, visibleComponentTypes, 'component_type'),
        ),
      );
    },
    [rawConversions],
  );

  return {
    filteredConversions,
    applyConversionFilters,
  };
};

export default useConversionMetrics;
