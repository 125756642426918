import PropTypes from 'prop-types';
import {Youtube} from 'mdi-material-ui';

import AdvocateLoginLogo from './components/AdvocateLoginLogo';
import SplitLogin from './components/SplitLogin';
import BroadcasterLogin from './components/BroadcasterLogin';
import ManagerLogin from './components/ManagerLogin';
import ResetRequest from './components/ResetRequest';
import ResetSuccess from './components/ResetSuccess';
import ResetPassword from './components/ResetPassword';
import Tos from './components/Tos';
import EmailConfirmation from './components/EmailConfirmation';

import {Route} from 'react-router-dom';
import PrivateRoute from 'components/components/Route/PrivateRoute';
import LoginTemplate from 'components/components/LoginTemplate';

const Login = () => (
  <LoginTemplate>
    <div className="logoContainer">
      <AdvocateLoginLogo />
    </div>
    <Route exact path="/login" component={SplitLogin} />
    <Route
      exact
      path={['/login/broadcaster', '/login/chatbot']}
      component={() => <BroadcasterLogin hasPermissionDetails />}
    />
    <Route
      exact
      path="/login/youtube"
      component={() => (
        <BroadcasterLogin
          linkText="Log In With Youtube"
          baseUrl="https://accounts.google.com/o/oauth2/v2/auth"
          loginClientId={YOUTUBE_CLIENT_ID}
          loginCallbackUrl={YOUTUBE_CALLBACK_URL}
          icon={Youtube}
          provider="youtube"
          scopes={[
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/youtube.readonly',
          ]}
        />
      )}
    />
    <Route path="/login/manager" component={ManagerLogin} />
    <Route path="/login/reset-request" component={ResetRequest} />
    <Route path="/login/reset-password/:uid/:token" component={ResetPassword} />
    <Route path="/login/reset-success" component={ResetSuccess} />
    <Route path="/login/email-confirmation/:key" component={EmailConfirmation} />
    <PrivateRoute path="/login/tos" component={Tos} />
  </LoginTemplate>
);

Login.propTypes = {
  children: PropTypes.node,
};

export default Login;
