import {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {getCookie, removeCookie} from 'redux-cookie';
import {isPending, hasFailed} from 'redux-saga-thunk';
import {parse} from 'query-string';

import {
  authOAuth2CodeRequest,
  managementSetChatbotRequest,
  managementSetTeamChatbotRequest,
} from 'store/actions';
import SocialCallback from '../SocialCallback';

const OAuthLoginCallbackPage = ({provider = 'twitch'}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const clearCookie = useCallback(
    (cookieName) => {
      dispatch(removeCookie(cookieName, {path: '/'}));
    },
    [dispatch],
  );

  /*
   * Invite codes go to the same endpoint as regular Broadcaster logins,
   * but send an extra `campaignInviteKey`/`teamInviteKey` param to also have
   * that broadcaster join the campaign. Since that was stored in a cookie
   * (to survive the roundtrip to twitch.tv) we also want to clear the cookie
   */
  const handleCampaignInvite = useCallback(
    (code, provider, campaignInviteKey, campaignInviteLabel) => {
      clearCookie('campaignInviteKey');
      clearCookie('campaignInviteLabel');
      return dispatch(
        authOAuth2CodeRequest(code, provider, {
          campaignInviteKey,
          ...(campaignInviteLabel && {label: campaignInviteLabel}),
        }),
      );
    },
    [clearCookie, dispatch],
  );

  const handleTeamInvite = useCallback(
    (code, provider, teamInviteKey) => {
      clearCookie('teamInviteKey');
      return dispatch(authOAuth2CodeRequest(code, provider, {teamInviteKey}));
    },
    [clearCookie, dispatch],
  );

  /*
   * Setting a new chatbot account on a campaign still requires going through
   * the full Twitch OAuth (because the platform needs to be able to connect to
   * chat via that account), but we don't actually want to login as that account
   * in the platform.  Thus, updating the chatbot hits a different endpoint/saga
   * entirely -- essentilly, a subset of campaign detail
   */
  const handleSetChatbot = useCallback(
    (code, campaign) => {
      clearCookie('setForCampaign');
      return dispatch(managementSetChatbotRequest(code, campaign));
    },
    [dispatch, clearCookie],
  );

  const handleSetTeamChatbot = useCallback(
    (code, team) => {
      clearCookie(dispatch, 'setForTeam');
      return dispatch(managementSetTeamChatbotRequest(code, team));
    },
    [clearCookie, dispatch],
  );

  // Redirect based on extent cookies, or do the default Broadcaster logins
  const sendOAuth2Code = useCallback(
    (code, provider) => {
      const campaignInviteKey = dispatch(getCookie('campaignInviteKey'));
      const campaignInviteLabel = dispatch(getCookie('campaignInviteLabel'));
      if (campaignInviteKey)
        return handleCampaignInvite(code, provider, campaignInviteKey, campaignInviteLabel);

      const teamInviteKey = dispatch(getCookie('teamInviteKey'));
      if (teamInviteKey) return handleTeamInvite(code, provider, teamInviteKey);

      const campaign = dispatch(getCookie('setForCampaign'));
      if (campaign) return handleSetChatbot(code, campaign);

      const team = dispatch(getCookie('setForTeam'));
      if (team) return handleSetTeamChatbot(code, team);

      return dispatch(authOAuth2CodeRequest(code, provider));
    },
    [dispatch, handleCampaignInvite, handleSetChatbot, handleSetTeamChatbot, handleTeamInvite],
  );

  const loading = useSelector(
    (state) => isPending(state, 'oauth2Code') || isPending(state, 'setCampaignChatbot'),
  );
  const failed = useSelector(
    (state) => hasFailed(state, 'oauth2Code') || hasFailed(state, 'setCampaignChatbot'),
  );

  const {code} = parse(location.search);

  const sendCallbackData = useCallback(
    () => sendOAuth2Code(code, provider),
    [code, provider, sendOAuth2Code],
  );

  const props = {
    sendCallbackData,
    loading,
    failed,
  };

  return <SocialCallback {...props} />;
};

OAuthLoginCallbackPage.propTypes = {
  provider: PropTypes.string,
};

export default OAuthLoginCallbackPage;
