import PropTypes from 'prop-types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {setCookie} from 'redux-cookie';
//import {youtubeURLRequest} from 'store/actions';
import {Youtube} from 'mdi-material-ui';

import Button from 'components/molecules/Button';

const scopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/youtube.readonly',
];

const getButtonMessaging = (screenName) => {
  if (screenName) {
    return 'Change';
  }
  return 'Connect your Youtube account';
};

const YoutubeProfileDisplay = ({name}) => {
  const dispatch = useDispatch();

  const youtubeConnect = useCallback(() => {
    dispatch(
      setCookie('advSourceDomain', window.location.host, {
        path: '/',
        domain: IS_DEV ? 'localhost' : '.adv.gg',
      }),
    );

    const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${YOUTUBE_CLIENT_ID}&redirect_uri=${encodeURIComponent(
      YOUTUBE_CALLBACK_URL,
    )}&scope=${scopes.join('+')}`;
    window.location.href = url;
  }, [dispatch]);

  return (
    <div className="socialDisplay youtube">
      <Youtube />
      {!!name && (
        <div className="userDisplay">
          <h4>{`${name}`}</h4>
        </div>
      )}
      <Button handleClick={youtubeConnect}>{getButtonMessaging(name)}</Button>
    </div>
  );
};

YoutubeProfileDisplay.propTypes = {
  name: PropTypes.string,
};

export default YoutubeProfileDisplay;
