/* eslint-disable react/no-array-index-key */
import {useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isPending} from 'redux-saga-thunk';
import history from 'localHistory';
import {setCookie} from 'redux-cookie';
import PropTypes from 'prop-types';

import TextFilter from 'components/components/TextFilter';
import HamburgerMenu from 'components/components/HamburgerMenu';
import LabelSelect from 'components/components/LabelSelect';
import StaticDateRanges from 'components/molecules/StaticDateRanges';
import Pagination from 'components/molecules/Pagination';
import Button from 'components/molecules/Button';
import EmptySplash from 'components/molecules/EmptySplash';
import InviteListModal from 'components/organisms/InviteListModal';
import StatsTable from 'components/organisms/StatsTable';
import ChannelOverviewPreview from '../../../../../../components/ChannelOverviewPreview';
import {AdvModal} from 'containers';
import SponsorCampaignCreationForm from 'components/components/CampaignCreationForm/SponsorCampaignCreationForm';
import RemoveBroadcasterForm from 'components/components/BroadcasterParticipationForm/RemoveBroadcasterForm';
import TeamAddBroadcasterForm from 'components/components/BroadcasterParticipationForm/TeamAddBroadcasterForm';
import SquadManagementForm from '../../../../../../forms/SquadManagementForm';
import InviteLinkDisplay from 'components/components/InviteLinkDisplay';
import ChatbotSelect from 'components/components/ChatbotSelect';
import {fromMetrics} from 'store/selectors';
import CampaignPreviewSection from '../../../../../../components/CampaignPreviewSection';
import {ACTIVE} from 'store/management/teamStatuses';
import {isCampaignEnded} from 'utils/numberFormats';
import {useSquads} from 'hooks';
import useModal from 'hooks/useModal';
import usePermissions from 'hooks/usePermissions';
import EditTeamForm from '../EditTeamForm';

const TeamOverview = ({
  team,
  campaigns,
  broadcasters,
  selectedDateRange,
  handleDateRangeChange,
}) => {
  const {canEditTeams} = usePermissions();

  const [modalShow] = useModal();
  const dispatch = useDispatch();
  const setChatbot = () => {
    // cookie to set this request as a chatbot-set rather than a login
    dispatch(setCookie('setForTeam', team.id, {path: '/'}));
    // redirect to the chatbot login
    history.push('/login/broadcaster');
  };

  const broadcasterStats = useSelector((state) =>
    fromMetrics.getMetric(state, 'team-broadcasters'),
  );
  const campaignStats = useSelector((state) => fromMetrics.getMetric(state, 'dashboard-campaigns'));
  const squads = useSquads(team.id);
  const metricsLoading = useSelector((state) =>
    isPending(state, 'team-broadcastersMetricsRequest'),
  );

  const [selectedFilter, setSelectedFilter] = useState('hours-watched');
  const [selectedSquad, setSelectedSquad] = useState(null);
  const [textFilter, setTextFilter] = useState('');

  const isActive = (broadcaster) => {
    return broadcaster.team_statuses[team.id].status === ACTIVE;
  };

  const sortChannels = (a, b) => {
    const aPlatform = a.platforms[0];
    const bPlatform = b.platforms[0];
    const aStats =
      broadcasterStats && broadcasterStats[selectedDateRange]
        ? broadcasterStats[selectedDateRange][a.username]
        : undefined;
    const bStats =
      broadcasterStats && broadcasterStats[selectedDateRange]
        ? broadcasterStats[selectedDateRange][b.username]
        : undefined;

    const defaultOption =
      aStats && bStats && aPlatform && bPlatform
        ? bStats[bPlatform].average_viewers * bStats[bPlatform].hours_streamed -
            aStats[aPlatform].average_viewers * aStats[aPlatform].hours_streamed ||
          (a.username > b.username ? -1 : 1)
        : 0;

    if (aStats && bStats) {
      switch (selectedFilter) {
        case 'hours-watched':
          return defaultOption;
        case 'hours-streamed':
          return bStats[bPlatform].hours_streamed - aStats[aPlatform].hours_streamed;
        case 'average-viewers':
          return bStats[bPlatform].average_viewers - aStats[aPlatform].average_viewers;
        default:
          return defaultOption;
      }
    }
    return 0;
  };

  const filteredBroadcasters = broadcasters.filter(
    (channel) =>
      (!textFilter || channel.username.toLowerCase().indexOf(textFilter.toLowerCase()) !== -1) &&
      (!selectedSquad ||
        squads.find((s) => s.id.toString() === selectedSquad).roster.includes(channel.username)),
  );

  const broadcasterTotals = {
    30: {},
    60: {},
    90: {},
    all: {},
  };
  const campaignTotals = {
    30: {},
    60: {},
    90: {},
    all: {},
  };

  Object.keys(broadcasterStats).forEach((dateRange) => {
    broadcasterTotals[dateRange] = filteredBroadcasters.reduce(
      (a, b) => {
        const broadcasterBStats = broadcasterStats[dateRange][b.username];
        const broadcasterB = broadcasterBStats && broadcasterBStats[b.platforms[0]];
        const broadcasterBimp = broadcasterBStats && broadcasterBStats.impressions;

        return broadcasterB
          ? {
              average_viewers: a.average_viewers + broadcasterB.average_viewers,
              hours_streamed: a.hours_streamed + broadcasterB.hours_streamed,
              viewable_minutes:
                a.viewable_minutes +
                broadcasterB.hours_streamed * 60 * broadcasterB.average_viewers,
              impressions: a.impressions + broadcasterBimp.impressions,
              display_count: a.display_count + broadcasterBimp.display_count,
            }
          : a;
      },
      {
        average_viewers: 0,
        hours_streamed: 0,
        viewable_minutes: 0,
        impressions: 0,
        display_count: 0,
      },
    );
  });

  Object.keys(campaignStats).forEach((dateRange) => {
    campaignTotals[dateRange] = campaigns.reduce(
      (a, b) => {
        const campaignB = campaignStats[dateRange][b.slug] || {
          microsite_visits: 0,
          viewable_minutes: 0,
        };
        return {
          microsite_visits: a.microsite_visits + campaignB.microsite_visits,
          viewable_minutes:
            a.viewable_minutes +
            campaignB.twitch_viewable_minutes +
            campaignB.mixer_viewable_minutes,
        };
      },
      {
        microsite_visits: 0,
        viewable_minutes: 0,
      },
    );
  });

  const teamCampaignStatsLabeled = [
    {
      label: 'Total Viewable Minutes',
      value: broadcasterTotals[selectedDateRange].viewable_minutes,
      oldValue:
        broadcasterTotals['60'].viewable_minutes -
        broadcasterTotals[selectedDateRange].vizewable_minutes,
      firstThreshold: 0.05,
      secondThreshold: 0.2,
    },
    {
      label: 'Total Clicks',
      value: campaignTotals[selectedDateRange].microsite_visits,
      oldValue:
        campaignTotals['60'].microsite_visits - campaignTotals[selectedDateRange].microsite_visits,
      firstThreshold: 0.1,
      secondThreshold: 0.3,
    },
    {
      label: 'Average CVM',
      value:
        campaignTotals[selectedDateRange].microsite_visits /
        (broadcasterTotals[selectedDateRange].viewable_minutes / 1000000),
      tooltip: 'Clicks per Million Viewed Minutes',
    },
    {
      label: 'Average Viewers',
      value: broadcasterTotals[selectedDateRange].hours_streamed
        ? broadcasterTotals[selectedDateRange].viewable_minutes /
          (broadcasterTotals[selectedDateRange].hours_streamed * 60)
        : 0,
    },
    {
      label: 'Total Impressions',
      value: broadcasterTotals[selectedDateRange].impressions,
    },
    {
      label: 'Total Display Counts',
      value: broadcasterTotals[selectedDateRange].display_count,
    },
  ];

  const activeBroadcasterCount = useMemo(() => {
    return broadcasters.filter((broadcaster) => {
      return broadcaster.team_statuses[team.id]?.status === 'Active';
    }).length;
  }, [broadcasters, team]);

  const teamChannelCampaignStatsLabeled = [
    {
      label: 'Team Members',
      value: activeBroadcasterCount,
    },
    {
      label: 'Active Sponsors',
      value: campaigns.filter((campaign) => !isCampaignEnded(campaign)).length,
    },
  ];

  // Takes squads of the form [{ id, [broadcasters] }, ...] and returns an
  // object of squad ids by broadcaster key { broadcaster: squadId, ...}
  const formattedSquads = squads.reduce(
    (squadsAcc, squad) => ({
      ...squadsAcc,
      ...squad.roster.reduce(
        (rosterAcc, username) => ({
          ...rosterAcc,
          [username]: squad.id,
        }),
        {},
      ),
    }),
    {},
  );

  const menuOptions = useMemo(
    () => [
      {
        text: 'Invite to Team',
        modal: `add-broadcaster-to-team-${team.id}`,
        showReadOnly: false,
      },
      {
        text: 'See Sent Invites',
        modal: `sent-invites-${team.id}`,
        showReadOnly: true,
      },
      {
        text: 'Remove from Team',
        modal: `remove-broadcaster-from-team-${team.id}`,
        showReadOnly: false,
      },
      {
        text: 'Manage Squads',
        modal: `manage-squads-${team.id}`,
        showReadOnly: false,
      },
      {
        text: 'Add a Sponsor',
        modal: `add-sponsor-${team.id}`,
        showReadOnly: false,
      },
      {
        text: 'Set Default Chatbot',
        modal: `set-team-chatbot-${team.id}`,
        showReadOnly: false,
      },
      {
        text: 'Edit Team Settings',
        modal: `edit-team-settings-${team.id}`,
        showReadOnly: false,
      },
    ],
    [team.id],
  );

  return (
    <div className="teamOverview">
      <span className="titleBar">
        <img className="teamLogo" alt="" src={team.logo} />
        <h1>{team.name}</h1>
        <HamburgerMenu>
          {menuOptions.map((item) =>
            item.showReadOnly || canEditTeams ? (
              <div
                key={item.text}
                className="menuItem"
                tabIndex={-1}
                onClick={() => modalShow(item.modal)}
                role="button"
              >
                <h4>{item.text}</h4>
              </div>
            ) : null,
          )}
        </HamburgerMenu>
      </span>
      <div className="overviewSection">
        <span className="titleBar">
          <h1>Broadcasters</h1>
          {broadcasters.length !== 0 && (
            <span className="filterSection">
              <TextFilter
                placeholder="Filter Channels"
                handleChange={(e) => setTextFilter(e.target.value)}
              />
              <LabelSelect label="Sort by" handleChange={setSelectedFilter}>
                <option value="hours-watched">Hours Watched</option>
                <option value="hours-streamed">Hours Streamed</option>
                <option value="average-viewers">Average Viewers</option>
              </LabelSelect>
              {squads.length > 0 && (
                <LabelSelect label="Filter Squad" handleChange={setSelectedSquad}>
                  <option value="">All</option>
                  {squads.map((squad) => (
                    <option key={squad.id} value={squad.id}>
                      {squad.name}
                    </option>
                  ))}
                </LabelSelect>
              )}
            </span>
          )}
        </span>
        <Pagination
          itemsPerPage={12}
          hasShowAll
          initialItems={broadcasters}
          items={filteredBroadcasters
            .filter(isActive)
            .sort(sortChannels)
            .map((broadcaster) => (
              <ChannelOverviewPreview
                broadcaster={broadcaster}
                campaigns={campaigns}
                broadcasterStats={
                  broadcasterStats &&
                  broadcasterStats[selectedDateRange] &&
                  broadcasterStats[selectedDateRange][broadcaster.username]
                }
                key={`channelPreview-${broadcaster.username}`}
                squads={squads}
                squad={formattedSquads[broadcaster.username]}
                modalShow={modalShow}
              />
            ))}
        >
          <div className="emptyBroadcasters">
            <EmptySplash>
              <h2>There are no broadcasters on this team yet!</h2>
              <h2>Invite broadcasters using the button above</h2>
            </EmptySplash>
          </div>
        </Pagination>
      </div>
      <div className="topStatsSection">
        <StatsTable
          metrics={metricsLoading ? null : teamChannelCampaignStatsLabeled}
          loadingThunk="team-broadcastersMetricsRequest"
          showLoadingText
        />
        <span className="statsDateRange">
          <StaticDateRanges
            handleChange={handleDateRangeChange}
            dateRanges={['07', '30', '90', 'all']}
            selectedRange={selectedDateRange.toString()}
          />
          <StatsTable
            metrics={metricsLoading ? null : teamCampaignStatsLabeled}
            loadingThunk="team-broadcastersMetricsRequest"
            showLoadingText
          />
        </span>
        <CampaignPreviewSection team={team} campaigns={campaigns} />
      </div>

      <AdvModal name={`add-broadcaster-to-team-${team.id}`} contentLabel="Add Broadcaster To Team">
        <TeamAddBroadcasterForm
          team={team}
          destination="Team"
          extraAutoSuggestParams={{exclude_on_team: team.id}}
        />
        <InviteLinkDisplay inviteType="teams" needle={team.id.toString()} route="join" />
      </AdvModal>

      <InviteListModal needle={team.id.toString()} identifier="team" broadcasters={broadcasters} />

      <AdvModal
        name={`remove-broadcaster-from-team-${team.id}`}
        contentLabel="Remove Broadcaster From Team"
      >
        <RemoveBroadcasterForm
          team={team}
          broadcasters={filteredBroadcasters.filter(isActive)}
          filterField="username"
        />
      </AdvModal>

      <AdvModal name={`manage-squads-${team.id}`} contentLabel="Manage Squads">
        <SquadManagementForm team={team.id} squads={squads} />
      </AdvModal>

      <SponsorCampaignCreationForm
        modalName={`add-sponsor-${team.id}`}
        teamBroadcasters={broadcasters.filter(isActive)}
        squads={squads}
        team={team}
        initialValues={{seconds_per_impression: team.seconds_per_impression}}
      />

      <AdvModal name={`set-team-chatbot-${team.id}`} contentLabel="Chatbot Settings">
        <label htmlFor="Chatbot Account">Chatbot Account</label>
        <div className="loginButton">
          <Button handleClick={setChatbot}>Authorize New Twitch Account</Button>
        </div>
        <hr />
        <h4 className="chatbotName">
          {team.default_chatbot ? 'Default chatbot:' : 'No default chatbot authorized'}
          {team.approved_chatbots?.length > 0 && (
            <ChatbotSelect
              chatbotScope="team"
              errorMessage="Could not update chatbot"
              team={team}
            />
          )}
        </h4>
      </AdvModal>

      <AdvModal name={`edit-team-settings-${team.id}`} contentLabel="Edit Team">
        <EditTeamForm team={team} />
      </AdvModal>
    </div>
  );
};

TeamOverview.propTypes = {
  team: PropTypes.object,
  name: PropTypes.string,
  modalShow: PropTypes.func,
  campaigns: PropTypes.arrayOf(PropTypes.object),
  broadcasters: PropTypes.arrayOf(PropTypes.object),
  broadcasterStats: PropTypes.object,
  campaignStats: PropTypes.object,
  selectedDateRange: PropTypes.string,
  handleDateRangeChange: PropTypes.func,
  squads: PropTypes.arrayOf(PropTypes.object),
  metricsLoading: PropTypes.bool,
  setChatbot: PropTypes.func,
};

export default TeamOverview;
