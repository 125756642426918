module.exports = {
  sassVariables: {
    primary: '#f9427f',
    secondary: '#113961',
    'navbar-back-rotation': '0',
    'navbar-link-rotation': '0',
    'login-background': "url('/images/login-background.jpg')",
    'secondary-background': "url('/images/secondary-bg.jpg')",
  },
  images: {
    loginLogo: '/images/white-logo.png',
    squareLogo: '/images/corner-adv-icon.png',
    fullLogo: '/images/white-logo.png',
  },
}
