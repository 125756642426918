import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import OAuthLoginCallbackPage from './components/OAuthLoginCallbackPage';
import TwitterCallbackPage from './components/TwitterCallbackPage';
import DiscordCallbackPage from './components/DiscordCallbackPage';
import PrivateRoute from 'components/components/Route/PrivateRoute';
import LoginTemplate from 'components/components/LoginTemplate';

const Login = () => (
  <LoginTemplate>
    <Route path="/auth/twitch" component={OAuthLoginCallbackPage} />
    <Route path="/auth/youtube" component={() => <OAuthLoginCallbackPage provider="youtube" />} />
    <PrivateRoute path="/auth/twitter" component={TwitterCallbackPage} />
    <PrivateRoute path="/auth/discord" component={DiscordCallbackPage} />
  </LoginTemplate>
);

Login.propTypes = {
  children: PropTypes.node,
};

export default Login;
