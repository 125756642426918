import {useState} from 'react';
import PropTypes from 'prop-types';
import {
  AccountCardDetails,
  FormatListBulletedSquare,
  CheckCircle,
  CircleSlice8,
} from 'mdi-material-ui';

import IconButton from 'components/components/IconButton';
import ToggleButton from 'components/components/ToggleButton';
import ChannelPreviewTopBar from 'components/molecules/ChannelPreviewTopBar';
import BroadcasterSquadForm from '../../forms/BroadcasterSquadForm';
import {AdvModal} from 'containers';
import {formatNumber, formatDate, isCampaignEnded} from 'utils/numberFormats';

const ChannelOverviewPreview = ({
  broadcaster,
  broadcasterStats,
  campaigns,
  squads,
  modalShow,
  squad,
}) => {
  const [selectedView, setSelectedView] = useState(true);
  // Calculates comma-separated names list of all Active campaigns for broadcaster
  const campaignList = campaigns.map((campaign) => ({
    ...campaign,
    hasBroadcaster:
      broadcaster.campaign_statuses[campaign.slug] &&
      broadcaster.campaign_statuses[campaign.slug].status === 'Active',
    broadcasterJoined:
      broadcaster.campaign_statuses[campaign.slug] &&
      broadcaster.campaign_statuses[campaign.slug].responded_at,
  }));
  const activeCampaigns = campaignList.filter((c) => !!c.hasBroadcaster).length;
  const endedCampaigns = campaignList.filter((c) => isCampaignEnded(c)).length;

  const activeSquad = squads && squads.find((s) => s.roster.includes(broadcaster.username));

  const sortCampaigns = (a, b) => {
    if (b.hasBroadcaster && a.hasBroadcaster) {
      return b.broadcasterJoined > a.broadcasterJoined ? 1 : -1;
    }
    if (b.hasBroadcaster) return 1;
    return -1;
  };

  const mainPlatform = broadcaster && broadcaster.platforms[0];

  const {[mainPlatform]: viewership = {}} = broadcasterStats || {};

  return (
    <span className="campaignChannelPreviewWrapper">
      <div className="channelOverviewWrapper">
        <div>
          <div className="channelOverviewPreview">
            <div className="backgroundImage" />
            <ChannelPreviewTopBar broadcaster={broadcaster} mainPlatform={mainPlatform}>
              {squads && (
                <IconButton
                  tooltip="Set Squad"
                  label="squads"
                  handleClick={() => modalShow(`broadcaster-assign-squad-${broadcaster.username}`)}
                >
                  <AccountCardDetails />
                </IconButton>
              )}
              {activeCampaigns > 0 && (
                <IconButton
                  handleClick={() => modalShow(`broadcaster-campaigns-${broadcaster.username}`)}
                  tooltip="See Campaign Participation"
                  label="campaigns"
                >
                  <FormatListBulletedSquare />
                </IconButton>
              )}
            </ChannelPreviewTopBar>
            {broadcaster.is_live && <h4 className="liveText">LIVE</h4>}
            <div className="contentSection">
              <div className="stats">
                <p>
                  Hours Streamed:
                  <b> {viewership ? formatNumber(viewership.hours_streamed) : ' ...'}</b>
                </p>
                <p>
                  Average Viewers:
                  <b> {viewership ? formatNumber(viewership.average_viewers) : ' ...'}</b>
                </p>
                {activeSquad && <p className="squadName">{activeSquad.name}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeCampaigns > 0 && (
        <AdvModal
          name={`broadcaster-campaigns-${broadcaster.username}`}
          contentLabel="Campaign participation for this broadcaster"
        >
          <label htmlFor="broadcaster campaigns">{`Campaigns for ${broadcaster.username}`}</label>
          {endedCampaigns > 0 && <div className="broadcasterBar" />}
          {endedCampaigns > 0 && (
            <div className="toggleOption">
              <h4>See ended campaigns</h4>
              <ToggleButton
                selectedIndex={selectedView}
                handleClick={() => setSelectedView(!selectedView)}
              />
            </div>
          )}
          {campaignList
            .filter((c) => !selectedView || !isCampaignEnded(c))
            .sort(sortCampaigns)
            .map((campaign) => (
              <div className="broadcasterActionListItem" key={campaign.slug}>
                <span className="content inline">
                  {campaign.hasBroadcaster ? (
                    <CheckCircle />
                  ) : (
                    <CircleSlice8 className="uncolored" />
                  )}
                  <span>
                    <h4>
                      {campaign.name} {isCampaignEnded(campaign) ? '(ended)' : ''}
                    </h4>
                    {campaign.hasBroadcaster && campaign.broadcasterJoined && (
                      <p>
                        {'Joined '}
                        {formatDate(campaign.broadcasterJoined)}
                      </p>
                    )}
                  </span>
                </span>
              </div>
            ))}
        </AdvModal>
      )}

      <AdvModal
        name={`broadcaster-assign-squad-${broadcaster.username}`}
        contentLabel="Assign Squad to broadcaster"
      >
        <BroadcasterSquadForm
          squads={squads}
          broadcaster={broadcaster}
          campaigns={campaigns}
          broadcasterSquad={squad}
          initialValues={{squad}}
        />
      </AdvModal>
    </span>
  );
};

ChannelOverviewPreview.propTypes = {
  broadcaster: PropTypes.object,
  broadcasterStats: PropTypes.object,
  campaigns: PropTypes.arrayOf(PropTypes.object),
  modalShow: PropTypes.func,
  squads: PropTypes.arrayOf(PropTypes.object),
  squad: PropTypes.number,
};

export default ChannelOverviewPreview;
